import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AddUserProfileCompanyDetails, CreateNewCustomer, CreateNewCustomerFail, CreateNewCustomerSuccess, ResetCustomerPassword, ResetCustomerPasswordFail, ResetCustomerPasswordSuccess } from '../../action/customer.actions';
import { SalesService } from '../../../sales-portal/services/sales.service';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { SaveBillingCycle } from '../../action/payments.actions';
import { SalesPortalDestroyed } from '../../action/sales-portal.actions';
import { Utils } from 'src/app/Utils';
import { SalesBillingSelectors } from '../../selectors/sales-billing.selectors';
import { FetchCustomerDetails } from 'src/app/customer-info-summary-page/store/actions/customer.actions';
import { WhatsappActions } from 'src/app/customer-data-components/notifications/store/actions/opt-in-whatsapp';
import { SALES_PORTAL_ROUTE } from 'src/app/constants';
import { Navigate } from '@ngxs/router-plugin';
import { DigitalIdentityCustomer } from 'src/app/customer-data-components/axiom/digital-identity/types/digital-identity-by-customer-id-response.interface';
import { RicaAuthorityActions } from 'src/app/sales/sales-portal/rica-authority-page/rica-authority-store/actions';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { SalesCartState } from '../sales-cart.state';
import { AddKnownCustomerEvent } from 'src/app/shared/customer-ticket/store/actions/ticket-event.actions';

const getDefaults = () => {
  return {
    loading: false
  }
}

export type CreateCustomerInfoStateModel = {
  loading: boolean;
}

@State<CreateCustomerInfoStateModel>({
  name: 'CreateCustomerState',
  defaults: getDefaults()
})
@Injectable()
export class CreateCustomerState {

  @Selector()
  static isLoading(state: CreateCustomerInfoStateModel) { return state.loading }

  constructor(
    private salesService: SalesService,
    private store: Store
  ) { }

  @Action(CreateNewCustomer)
  createNewCustomer(ctx: StateContext<CreateCustomerInfoStateModel>, action: CreateNewCustomer) {
    const { payload } = action;

    ctx.patchState({
      loading: true
    });

    return this.salesService.createCustomer(payload)
      .pipe(
        tap({
          next: (res: DigitalIdentityCustomer) => ctx.dispatch(new CreateNewCustomerSuccess({response: res, optIn: payload?.optIn})),
          error: (e: unknown) => ctx.dispatch(new CreateNewCustomerFail(e))
        })
      );
  }

  @Action(CreateNewCustomerSuccess)
  CreateNewCustomerSuccess(ctx: StateContext<CreateCustomerInfoStateModel>, action: CreateNewCustomerSuccess) {
    const { response, optIn } = action.payload ?? {};
    const { id, contactMedium, relatedParty } = response ?? {};
    const { email } = contactMedium?.find(medium => medium?.contactType === "email")

    const { id: workId } = relatedParty?.find(party => party?.role === 'SME') ?? {}
    const { id: homeId } = relatedParty?.find(party => party?.role === 'Consumer' ) ?? {}
    const isWork = this.store.selectSnapshot(SalesCartState.smeSelected)

    const showWorkId = isWork && SFValidators.isDefined(workId)

    const billCycle = this.store.selectSnapshot(SalesBillingSelectors.getSelectedBillCycle);

    const fetchCustomerAction = new FetchCustomerDetails({
      value: email,
      config: {
        loader: true,
        autoNavigate: false
      }
    });

    ctx.patchState({
      loading: false
    })

    return ctx.dispatch([
      new AddSuccessResponseNotification({
        success: true,
        message: `New user created: ${email}`
      }),
      new AddKnownCustomerEvent({
        eventComment: 'new user created',
        reload: false,
        email: showWorkId ? Utils.Regex.addSMEToEmail(email) : email
      }),
      new ResetCustomerPassword(email),
      new SaveBillingCycle(
        { user_id: showWorkId ? workId : homeId, bill_cycle_spec: billCycle.id},
        { successActions: [fetchCustomerAction], failActions: [fetchCustomerAction] }
      ),
      new RicaAuthorityActions.SetRelatedPartyIDs(relatedParty),
      new WhatsappActions.OptIn(id, optIn),
    ]);
  }

  @Action(CreateNewCustomerFail)
  createNewCustomerFail(ctx: StateContext<CreateCustomerInfoStateModel>, action: CreateNewCustomerFail) {
    const error = Utils.Helpers.findError(action.error, "");

    ctx.patchState({
      loading: false
    })

    return ctx.dispatch(new AddSuccessResponseNotification({
      success: false,
      message: `Failed to create user. ${error}`
    }));

  }

  @Action(AddUserProfileCompanyDetails)
  addUserProfileCompanyDetails(ctx: StateContext<CreateCustomerInfoStateModel>, action: AddUserProfileCompanyDetails) {
    const { payload } = action

    return this.salesService.createCustomer(payload)
      .pipe(
        tap({
          next: res => {

            const { relatedParty } = res ?? {}

            ctx.dispatch([
              new RicaAuthorityActions.SetRelatedPartyIDs(relatedParty),
              new AddSuccessResponseNotification({
                success: true,
                message: `User profile successfully updated`
              }),
              new Navigate([`${SALES_PORTAL_ROUTE}/customer-delivery`])
            ])
          },
          error: (error: unknown) => {
            ctx.dispatch(new AddSuccessResponseNotification({
              success: false,
              message: `Failed to update user profile. ${error}`
            }))
          },
        })
      );
  }

  @Action(ResetCustomerPassword)
  resetCustomerPassword(ctx: StateContext<CreateCustomerInfoStateModel>, action: ResetCustomerPassword) {
    const { customerEmail } = action ?? {}

    return this.salesService.resetCustomerPassword(customerEmail)
      .pipe(
        tap({
          next: () => ctx.dispatch(new ResetCustomerPasswordSuccess()),
          error: (e: unknown) => ctx.dispatch(new ResetCustomerPasswordFail(e)),
        })
      );
  }

  @Action(ResetCustomerPasswordSuccess)
  resetCustomerPasswordSuccessful(ctx: StateContext<CreateCustomerInfoStateModel>) {
    return ctx.dispatch([
      new AddSuccessResponseNotification({
        success: true,
        message: `Password reset email successfully sent`
      }),
    ]);
  }

  @Action(ResetCustomerPasswordFail)
  resetCustomerPasswordFail(ctx: StateContext<CreateCustomerInfoStateModel>, action: ResetCustomerPasswordFail) {
    const error = Utils.Helpers.findError(action.error, "");

    return ctx.dispatch(new AddSuccessResponseNotification({
      success: false,
      message: `Failed to send password reset email. ${error}`
    }));
  }

  @Action(SalesPortalDestroyed)
  Clear(ctx: StateContext<CreateCustomerInfoStateModel>) {
    ctx.setState(getDefaults());
  }

}


