import { TechType } from "src/app/shared/services/google-maps/assets/techtype.type";

export const PRODUCT_TYPES: ProductCatalogType[] = [
    "sim",
    "device",
    "addon",
    "bundle",
    "delivery",
    "accessory",
    "isim"
];

export const GEFORCE_TIERS_IN_PRIORITY_ORDER = [
    "ultra",
    "priority",
    // "free"
] as const;

export type GeForceTier = typeof GEFORCE_TIERS_IN_PRIORITY_ORDER[number];

export interface ProductCatalogMap {
    addon: CatalogAddon[];
    bundle: CatalogBundle[];
    sim: CatalogSimProduct[];
    delivery: CatalogDelivery[];
    device: CatalogDevice[];
    accessory: CatalogSkin[];
    isim: CatalogISim[]
}

export type ProductCatalogType = keyof ProductCatalogMap;

export type ProductCatalogTypeWithPaymentType = Extract<ProductCatalogType, "addon" | "sim" | "bundle">;

export type RainPaymentType = "upfront" | "postpaid" | "prepaid";

export type MultiplierAmounts = {
    VOICE: number;
    DATA: number;
    SMS: number;
}

export type AddonSubType = "nvidia";

export interface CatalogProductConfig {
    level: number;
    migration: MigrationOption[];
    topup_multiplier?: {
        DATA: number;
        VOICE: number;
        SMS: number;
    };
    paymentType?: RainPaymentType;
    legacy?: boolean;
    base_subscription?: BaseSubscription
    maxLines: number;
    add_ons?: { id: string, allowed?: string[] }[];
    'spend-limit': {
      default: number,
      max:number,
      min: number
    },
    color?: string,
    hexColor?: string,
}

export interface BaseSubscription {
  VOICE: number;
  DATA: number;
  SMS: number;
}

export interface MigrationOption {
    id: string;
    name: string;
    config: unknown;
}

export interface CatalogProductBase {
    id: string;
    type: ProductCatalogType;
}

export interface CatalogItem {
    id: string;
    config: unknown;
}

export interface CatalogSimProduct {
    name: string | null;
    id: string;
    type: "sim";
    category: TechType | null;
    sku: string;
    config: CatalogProductConfig;
    price?: number;
    items?: CatalogItem[];
    recurringPrice?: number;
}

export interface CatalogStandaloneProduct {
  name: string;
  id: string;
  price: number;
  type: "bundle";
  config: {
    displayName: string,
    paymentType: RainPaymentType;
    saleType: "4G standalone"
  },
  items: CatalogItem[];


}

export interface CatalogDevice {
    name: string;
    id: string;
    type: "device"
    category: TechType
    sku: string;
    config: {
        migration: MigrationOption[]
    }
}

export interface CatalogISim {
    name: string;
    id: string;
    type: "isim"
    category: TechType
    sku: string;
    config: {
        migration: MigrationOption[]
    }
}
export interface CatalogSkin {
    name : string;
    id: string;
    type: "accessory";
    sku: string;
    config: {
        hexColor: string;
        color: string;
        available: boolean;
        colorSelector: string;
        form_factor: string;
    },
    price?: number,
    recurringPrice?: number,
    levelUpRecurringPrice?: number;
}

export interface CatalogAddon<ConfigType = AddonConfig> {
    name: string;
    id: string;
    price: number;
    recurringPrice: number;
    type: "addon";
    sku: string;
    config: ConfigType;
    levelUpRecurringPrice?: number;
}

export interface AddonConfig {
    paymentType?: RainPaymentType;
    migration: MigrationOption[];
    subtype?: AddonSubType;
}

export type NvidiaAddon = CatalogAddon<NvidiaConfig>;

export interface NvidiaConfig extends AddonConfig {
    rig?: string;
    features?: string[],
    rainCustomer?: boolean,
    tier?: GeForceTier,
    subtype?: "nvidia",
    discountedPrice: number
}

export interface CatalogBundle {
    name: string;
    id: string;
    price: number;
    recurringPrice: number;
    type: "bundle";
    config: {
        add_ons?: { id: string }[];
        paymentType: RainPaymentType;
        staff?: boolean;
        demographic?: string;
        displayName?: string;
        saleType?: string;
    },
    items: CatalogItem[];
    levelUpRecurringPrice?: number;
}

export interface FullCatalogBundle extends CatalogBundle {
    config: {
      displayName?: string,
      saleType?: string,
        add_ons?: CatalogAddon[];
        paymentType: RainPaymentType;
        staff?: boolean;
        demographic?: string
    },
    items: FullBundleItem[];
  level?: number;
}

export interface CatalogDelivery {
    name: null,
    id: string;
    type: "delivery",
    category: null,
    sku: string;
    config: {
        legacy: boolean;
        migration: MigrationOption[];
    }
}

type FullBundleItem = CatalogSimProduct | CatalogDevice | CatalogDelivery;

export type CatalogProduct = FullBundleItem | CatalogBundle | CatalogAddon | CatalogSkin;
