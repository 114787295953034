import { Selector, createSelector } from "@ngxs/store";
import { CustomerInfoState } from "../state/customer-info.state";
import { Customer, CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { ExtendedCustomerService } from "../interfaces/proxy-customer-service.interface";
import { ServiceFamily } from "../interfaces/service-family.interface";
import { SIM_TYPE_CODES } from "../interfaces/sim-type-code.interface";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { SimMethods } from "src/app/customer-data-components/sim-details/assets/sim-methods";
import { DataHandler } from "src/app/shared/data-handler/data-handler";
import { Utils } from "src/app/Utils";
import { NewServiceProxyState } from "../state/new-proxy-service.state";
import { NewProxyCustomerService } from "../interfaces/new-proxy-customer-service.interface";

export interface MappedServicesByOrderId {
    [orderId: string]: ExtendedCustomerService;
}

const getChildrenServices = (services: ExtendedCustomerService[], parentId: string) => services.filter(service => service?.parent_service_id === parentId);

export class ExtendedServiceSelectors {

    @Selector([CustomerInfoState.getCustomer, NewServiceProxyState.getData])
    static getServices(customer: Customer, proxyServices: NewProxyCustomerService[]): ExtendedCustomerService[] {
        const { services } = customer ?? {};

        if (!proxyServices?.length) {
            return services as ExtendedCustomerService[];
        }

        const mappedProxyServices = Utils.Mappers.toHashMapV2(proxyServices, "id");
        const assignedServices = proxyServices?.filter(service => !!service?.assigned_by);

        const customerServices = services?.map(service => {
            const { id } = service ?? {};
            const matchingProxyService = mappedProxyServices?.[id];

            if (!matchingProxyService) {
              return service as ExtendedCustomerService;
            }

            const {
                parent_service_id,
                additional_balances,
                pending_migration,
                sim_type_code,
                international_dialling,
                order_id,
                order_line_id,
                scheduled_action,
                assigned_by,
                subscriptions
            } = matchingProxyService ?? {};

            const service_reference = service?.service_reference ?? matchingProxyService?.service_reference;

            return {
                ...service,
                service_reference,
                parent_service_id,
                additional_balances,
                pending_migration,
                sim_type_code,
                international_dialling,
                order_id,
                order_line_id,
                scheduled_action,
                assigned_by,
                subscriptions
            }
        });

        return [...customerServices, ...assignedServices as any]
    }

    @Selector([ExtendedServiceSelectors.getServices])
    static getMappedServicesByOrderId(services: ExtendedCustomerService[]): MappedServicesByOrderId {

        return services?.reduce((serviceMap, currService) => {
            const { order_id } = currService?.order_details ?? {};
            if (order_id) {
                serviceMap[order_id] = currService;
            }
            return serviceMap;

        }, <MappedServicesByOrderId>{});

    }

    static getServiceByOrderId(orderId: string) {
        return createSelector(
            [ExtendedServiceSelectors.getMappedServicesByOrderId],
            (mappedServices: MappedServicesByOrderId): CustomerService | null => mappedServices?.[orderId]
        );
    }

    static getServiceById(id: string) {
        return createSelector(
            [ExtendedServiceSelectors.getServices],
            (services: ExtendedCustomerService[]) => services?.find(service => service?.id === id)
        )
    }

    static isESim(id: string) {
        return createSelector(
            [ExtendedServiceSelectors.getServiceById(id)],
            (service: ExtendedCustomerService) => service?.sim_type_code === SIM_TYPE_CODES.eSIM
        );
    }

    static checkIsESimById(id: string) {
        return createSelector(
            [ExtendedServiceSelectors.getServiceById(id)],
            (service: ExtendedCustomerService) => service?.sim_type_code === SIM_TYPE_CODES.eSIM
        );
    }

    static getServiceFamilyById(id: string) {

        return createSelector(
            [ExtendedServiceSelectors.getServices],
            (services: ExtendedCustomerService[]): ServiceFamily => {

                const currentService = services.find(service => service?.id === id);
                const isParent = !currentService?.parent_service_id;

                if (isParent) {
                    const children = getChildrenServices(services, currentService?.id);
                    return {
                        parentService: currentService,
                        children
                    }
                }
                //Is child
                const parentId = currentService.parent_service_id;
                const children = getChildrenServices(services, parentId);
                const parentService = services.find(service => service?.id === parentId);

                return {
                    parentService,
                    children
                }
            }
        );
    }

    static checkIfServiceIsCancelledById(id: string) {
        return createSelector(
            [ExtendedServiceSelectors.getServiceById(id)],
            (service: ExtendedCustomerService) => {
                if (!id) return null;

                return service?.status === CUSTOMER_SERVICE_STATUS['Service Canceled'];
            });
    }

    @Selector([ExtendedServiceSelectors.getServices])
    static getServiceLevelTenure(services: ExtendedCustomerService[]) {
        const oldestService = SimMethods.getSortedActiveServices(services, "asc")?.[0];
        if (!oldestService) {
            return 0;
        }

        return DataHandler.getDiffInTime("now", oldestService?.activation_date, "months");
    }

    @Selector([ExtendedServiceSelectors.getServices])
    static getActiveServices(services: ExtendedCustomerService[]): ExtendedCustomerService[] {

        return services?.filter(service => service?.status !== CUSTOMER_SERVICE_STATUS['Service Canceled'])

    }

}
