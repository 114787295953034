import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { PickupPoint } from '../../store/interfaces/pickup-point.interface';


@Injectable({
    providedIn: 'root'
})
export class PickupPointService {

    constructor(private firestore: AngularFirestore) { }

    fetchPickupLocations() {
        return this.firestore
            .doc<{ pickupPoints: PickupPoint[] }>(`${environment.fireStoreUrl}/pickup-points`)
            .snapshotChanges()
            .pipe(
                map(actions => {
                    const data = actions?.payload?.data();
                    return data?.pickupPoints;
                })
            );
    }

}