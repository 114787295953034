import { Selector } from "@ngxs/store";
import { SelectedServiceSelectors } from "./selected-service.selectors";
import { ExtendedCustomerService } from "../interfaces/proxy-customer-service.interface";
import { SalesCartState } from "src/app/sales/store/state/sales-cart.state";
import { AllOrderSelectors } from "./all-order.selectors";
import { ExtendedServiceSelectors, MappedServicesByOrderId } from "./extended-service.selector";
import { ProductCatalogState } from "src/app/core/store/state/product-state/product-catalog.state";
import { ConfigItem, CustomerOrder } from "src/app/customer-data-components/customer-order/customer-order-card/assets/customer-order.interface";
import { CatalogSimProduct } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { CUSTOMER_SERVICE_STATUS } from "src/app/shared/constants/service-status.constant";
import { SFValidators } from "src/app/shared/functions/sf-validators";
import { CustomerService } from "src/app/shared/interfaces/smartsub-data.interface";
import { Dictionary } from "src/app/shared/interfaces/dictionary.interface";
import { getParentServiceIdFromOrderLine } from "src/app/Utils/functional/get-parent-service-id-from-orderline";
import { LEGACY_4G_UOP, STAND_ALONE_4G } from "src/app/constants";

export class RainoneSelectors {

  @Selector([SelectedServiceSelectors.getSelectedService])
  static isParentService(selectedService: ExtendedCustomerService) {
    return !selectedService?.parent_service_id
  }

  @Selector([
    SalesCartState.rainOneSelected,
    RainoneSelectors.has5gRainone101OrderOrService
  ])
  static rainoneNotSelectedAndNotRainone(
    rainOneSelected: boolean,
    has5gRainone101OrderOrService: boolean
  ) {
    return !rainOneSelected && !has5gRainone101OrderOrService
  }

  @Selector([
    AllOrderSelectors.getOrders,
    ExtendedServiceSelectors.getMappedServicesByOrderId,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static allActiveRainoneOrders(
    orders: CustomerOrder[],
    mappedServices: MappedServicesByOrderId,
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    if (!orders?.length) {
      return []
    }

    return orders?.filter((order) => {

      const { id: orderId, status: orderStatus } = order ?? {}
      const matchingService = mappedServices?.[orderId];
      const { status } = matchingService ?? {};
      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
      const isCancelledOrRedeliveredOrder = orderStatus === "Order Cancelled" || orderStatus === "Redelivered"

      if (isCancelledService || isCancelledOrRedeliveredOrder) {
        return false
      }

      for (const orderLine of order?.order_lines) {

        const product = simProductDict?.[orderLine?.product_id];
        const { legacy } = product?.config ?? {}

        //Undefined legacy field means it is a legacy product
        const isRainOne = !legacy && SFValidators.isDefined(product);
        if (isRainOne) {
          return true;
        }
      }

      return false;
    }) ?? []
  }
  @Selector([
    AllOrderSelectors.getOrders,
    ExtendedServiceSelectors.getMappedServicesByOrderId,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static allActiveRainone101Orders(
    orders: CustomerOrder[],
    mappedServices: MappedServicesByOrderId,
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    if (!orders?.length) {
      return []
    }

    return orders?.filter((order) => {

      const { id: orderId, status: orderStatus } = order ?? {}
      const matchingService = mappedServices?.[orderId];
      const { status } = matchingService ?? {};
      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
      const isCancelledOrRedeliveredOrder = orderStatus === "Order Cancelled" || orderStatus === "Redelivered"

      if (isCancelledService || isCancelledOrRedeliveredOrder) {
        return false
      }

      for (const orderLine of order?.order_lines) {

        const product = simProductDict?.[orderLine?.product_id];
        const { name } = product ?? {}
        const { legacy } = product?.config ?? {}

        const isRainOne101 = !legacy && SFValidators.isDefined(product) && name.toLocaleLowerCase().includes('101');
        if (isRainOne101) {
          return true;
        }
      }

      return false;
    }) ?? []
  }
  @Selector([
    AllOrderSelectors.getOrders,
    ExtendedServiceSelectors.getMappedServicesByOrderId,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static allActiveRainone101WorkOrders(
    orders: CustomerOrder[],
    mappedServices: MappedServicesByOrderId,
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    if (!orders?.length) {
      return []
    }

    return orders?.filter((order) => {

      const { id: orderId, status: orderStatus } = order ?? {}
      const matchingService = mappedServices?.[orderId];
      const { status } = matchingService ?? {};
      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
      const isCancelledOrRedeliveredOrder = orderStatus === "Order Cancelled" || orderStatus === "Redelivered" || orderStatus === 'Collected' || orderStatus === 'Delivered'

      if (isCancelledService || isCancelledOrRedeliveredOrder) {
        return false
      }

      for (const orderLine of order?.order_lines) {

        const product = simProductDict?.[orderLine?.product_id];
        const { name } = product ?? {}
        const { legacy } = product?.config ?? {}

        const isRainOne101work = !legacy && SFValidators.isDefined(product) && (name.toLocaleLowerCase().includes('work') && !name.toLocaleLowerCase().includes('101'));
        if (isRainOne101work) {
          return true;
        }
      }

      return false;
    }) ?? []
  }

  @Selector([
    RainoneSelectors.allActiveRainoneOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has4gRainoneOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category, config } = simProductDict?.[product_id] ?? {};
        const is4G = category === '4G';
        const isPrepaid = config?.paymentType === 'prepaid';

        return is4G && !isPrepaid;
      })
    );

  }

  @Selector([
    RainoneSelectors.allActiveRainoneOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static has4gRainoneMigrationOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category } = simProductDict?.[product_id] ?? {}

        return category === '4G';
      })
    );

  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has4gRainoneService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { paymentType, legacy } = config ?? {};

      const is4G = category === '4G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const isPrepaid = paymentType === 'prepaid';

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is4G && !isLegacy && !isPrepaid && !isCancelledService;
    });

  }

  @Selector([
    RainoneSelectors.allActiveRainoneOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has5gRainoneOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category, name } = simProductDict?.[product_id] ?? {}; //TODO abdul - did this because I saw rainone orders were returning true for 101 orders

        return category === '5G' && (name?.toLowerCase() === 'rainone' && !name?.toLowerCase()?.includes('101'));
      })
    );
  }
  @Selector([
    RainoneSelectors.allActiveRainone101Orders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has5gRainone101Order(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category } = simProductDict?.[product_id] ?? {};

        return category === '5G';
      })
    );
  }

  @Selector([
    RainoneSelectors.allActiveRainone101WorkOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has5gRainone101WorkOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return allActiveRainoneOrders?.filter(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category, name } = simProductDict?.[product_id] ?? {};

        return category === '5G' && name?.toLocaleLowerCase()?.includes('rainone work');
      })
    );
  }
  @Selector([
    RainoneSelectors.allActiveRainone101WorkOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static hasMax5gRainone101WorkOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {
    return allActiveRainoneOrders?.filter(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category, name } = simProductDict?.[product_id] ?? {};

        return category === '5G' && name?.toLocaleLowerCase()?.includes('rainone work');
      })
    )?.length;
  }

  @Selector([
    RainoneSelectors.allActiveRainoneOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static has5gRainoneMigrationOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category, name } = simProductDict?.[product_id] ?? {}

        const nameMatch = name?.toLowerCase() === 'rainone' && name?.toLowerCase() !== '101'

        return category === '5G' && nameMatch;
      })
    );
  }

  @Selector([
    RainoneSelectors.allActiveRainone101Orders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static has5gRainone101MigrationOrder(
    allActiveRainone101Orders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActiveRainone101Orders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category } = simProductDict?.[product_id] ?? {}

        return category === '5G';
      })
    );
  }
  @Selector([
    RainoneSelectors.allActiveRainone101Orders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static has5gRainone101WorkMigrationOrder(
    allActiveRainone101Orders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActiveRainone101Orders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category, name } = simProductDict?.[product_id] ?? {}

        return category === '5G' && name?.toLocaleLowerCase()?.includes('rainone work');
      })
    );
  }
  @Selector([
    RainoneSelectors.allActiveRainone101Orders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static hasMax5gRainone101WorkMigrationOrder(
    allActiveRainone101Orders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActiveRainone101Orders?.filter(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category, name } = simProductDict?.[product_id] ?? {}

        return category === '5G' && name?.toLocaleLowerCase()?.includes('rainone work');
      })
    )?.length;
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has5gRainoneService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config, name } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is5G = category === '5G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const nameMatch = name?.toLowerCase() === 'rainone' && name?.toLowerCase() !== '101'

      const nameMatches = name?.toLowerCase() === 'rainone' && !name?.toLowerCase()?.includes('101')

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is5G && !isLegacy && !isCancelledService && nameMatches;
    });
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ProductCatalogState.getRainOne101FiveGSimsIDs
  ])
  static has5gRainone101Service(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>,
    rainone101ProductIds: string[]
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is5G = category === '5G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const productIdMatches =  rainone101ProductIds?.includes(product_id)

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is5G && !isLegacy && !isCancelledService && productIdMatches;
    });
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ProductCatalogState.getRainOne101WorkFiveGSimsIDs
  ])
  static has5gRainone101WorkService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>,
    rainone101ProductIds: string[]
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is5G = category === '5G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const productIdMatches =  rainone101ProductIds?.includes(product_id)

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is5G && !isLegacy && !isCancelledService && productIdMatches;
    });
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ProductCatalogState.getRainOne101WorkFiveGSimsIDs
  ])
  static hasMax5gRainone101WorkService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>,
    rainone101ProductIds: string[]
  ) {

    return services?.filter(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is5G = category === '5G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const productIdMatches =  rainone101ProductIds?.includes(product_id)

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is5G && !isLegacy && !isCancelledService && productIdMatches;
    })?.length;
  }

  @Selector([
    RainoneSelectors.has4gRainoneOrder,
    RainoneSelectors.has4gRainoneService,
    RainoneSelectors.has4gRainoneMigrationOrder
  ])
  static has4gRainoneOrderOrService(
    has4gRainoneOrder: boolean,
    has4gRainoneService: boolean,
    has4gRainoneMigrationOrder: boolean
  ) {
    return has4gRainoneOrder || has4gRainoneService || has4gRainoneMigrationOrder
  }

  @Selector([
    RainoneSelectors.has5gRainoneOrder,
    RainoneSelectors.has5gRainoneService,
    RainoneSelectors.has5gRainoneMigrationOrder
  ])
  static has5gRainoneOrderOrService(
    has5gRainoneOrder: boolean,
    has5gRainoneService: boolean,
    has5gRainoneMigrationOrder: boolean
  ) {
    return has5gRainoneOrder || has5gRainoneService || has5gRainoneMigrationOrder
  }

  @Selector([
    RainoneSelectors.has5gRainone101Order,
    RainoneSelectors.has5gRainone101Service,
    RainoneSelectors.has5gRainone101MigrationOrder
  ])
  static has5gRainone101OrderOrService(
    has5gRainoneOrder: boolean,
    has5gRainoneService: boolean,
    has5gRainoneMigrationOrder: boolean
  ) {
    return has5gRainoneOrder || has5gRainoneService || has5gRainoneMigrationOrder
  }

  @Selector([
    RainoneSelectors.has5gRainone101WorkOrder,
    RainoneSelectors.has5gRainone101WorkService,
    RainoneSelectors.has5gRainone101WorkMigrationOrder
  ])
  static has5gRainone101WorkOrderOrService(
    has5gRainoneOrder: boolean,
    has5gRainoneService: boolean,
    has5gRainoneMigrationOrder: boolean
  ) {
    return has5gRainoneOrder || has5gRainoneService || has5gRainoneMigrationOrder
  }

  @Selector([
    RainoneSelectors.hasMax5gRainone101WorkOrder,
    RainoneSelectors.hasMax5gRainone101WorkService,
    RainoneSelectors.hasMax5gRainone101WorkMigrationOrder
  ])
  static hasMax5gRainone101WorkOrderOrService(
    has5gRainoneOrder: number,
    has5gRainoneService: number,
    has5gRainoneMigrationOrder: number
  ) {
    return (has5gRainoneOrder + has5gRainoneService + has5gRainoneMigrationOrder) > 1;
  }

  @Selector([
    RainoneSelectors.allActiveRainoneOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static hasUOP4gRainoneOrder(
    allActiveRainoneOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    // TODO not the best way to do this

    return allActiveRainoneOrders?.some(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { name } = simProductDict?.[product_id] ?? {}

        return name?.includes(LEGACY_4G_UOP)

      })
    );

  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static hasUOP4gRainoneService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    return services?.some(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { paymentType, legacy, "spend-limit": spendLimit } = config ?? {};

      const is4G = category === '4G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const isPrepaid = paymentType === 'prepaid';

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is4G && !isLegacy && !isPrepaid && spendLimit && !isCancelledService;
    });

  }

  @Selector([
    RainoneSelectors.hasUOP4gRainoneOrder,
    RainoneSelectors.hasUOP4gRainoneService
  ])
  static hasUOP4gRainoneOrderOrService(
    hasUOP4gRainoneOrder: boolean,
    hasUOP4gRainoneService: boolean
  ) {
    return hasUOP4gRainoneOrder || hasUOP4gRainoneService
  }

  @Selector([
    AllOrderSelectors.getOrders,
    ExtendedServiceSelectors.getMappedServicesByOrderId,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static allActive4gMobileOrders(
    orders: CustomerOrder[],
    mappedServices: MappedServicesByOrderId,
    simProductDict: Dictionary<CatalogSimProduct>
  ) {

    if (!orders?.length) {
      return []
    }

    return orders?.filter(order => {
      const { id: orderId, status: orderStatus } = order ?? {}
      const matchingService = mappedServices?.[orderId];
      const { status } = matchingService ?? {};
      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];
      const isCancelledOrRedeliveredOrder = orderStatus === "Order Cancelled" || orderStatus === "Redelivered"

      if (isCancelledService || isCancelledOrRedeliveredOrder) {
        return false
      }

      for (const orderLine of order?.order_lines) {

        const product = simProductDict?.[orderLine?.product_id];
        const { name } = product ?? {}
        const { legacy } = product?.config ?? {}

        const is4gMobile = !legacy && SFValidators.isDefined(product) && name?.includes(STAND_ALONE_4G);
        if (is4gMobile) {
          return true;
        }
      }

      return false;
    }) ?? []
  }

  @Selector([
    RainoneSelectors.allActive4gMobileOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim")
  ])
  static has4gMobileOrder(
    allActive4gMobileOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>
  ) {
    return allActive4gMobileOrders?.filter(order =>
      order?.order_lines?.some(orderLine => {

        const { product_id } = orderLine ?? {}

        const { category, name } = simProductDict?.[product_id] ?? {};

        return category === '4G' && name?.includes(STAND_ALONE_4G);
      })
    )?.length;
  }

  @Selector([
    ExtendedServiceSelectors.getServices,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ProductCatalogState.get4gMobileSimsIDs
  ])
  static has4gMobileService(
    services: CustomerService[],
    simProductDict: Dictionary<CatalogSimProduct>,
    fourGMobileProductIds: string[]
  ) {

    return services?.filter(service => {
      const { product_id, status } = service ?? {}
      const { category, config } = simProductDict?.[product_id] ?? {};
      const { legacy } = config ?? {};

      const is4G = category === '4G';
      const isLegacy = SFValidators.isDefined(legacy) || legacy;
      const productIdMatches =  fourGMobileProductIds?.includes(product_id)

      const isCancelledService = SFValidators.isDefined(status) && status === CUSTOMER_SERVICE_STATUS["Service Canceled"];

      return is4G && !isLegacy && !isCancelledService && productIdMatches;
    })?.length;
  }

  @Selector([
    RainoneSelectors.allActive4gMobileOrders,
    ProductCatalogState.getProductsByTypeMappedById("sim"),
    ExtendedServiceSelectors.getServices
  ])
  static has4gMobileMigrationOrder(
    allActive4gMobileOrders: CustomerOrder[],
    simProductDict: Dictionary<CatalogSimProduct>,
    services: ExtendedCustomerService[]
  ) {

    return allActive4gMobileOrders?.filter(order =>
      order?.order_lines?.some(orderLine => {

        const { product_configs } = orderLine ?? {}

        const parentServiceId = getParentServiceIdFromOrderLine(<ConfigItem[]>product_configs?.configs)
        const { product_id } = ExtendedServiceSelectors.getServiceById(parentServiceId)(services) ?? {}
        const { category, name } = simProductDict?.[product_id] ?? {}

        return category === '4G' && name?.includes(STAND_ALONE_4G);
      })
    )?.length;
  }

  @Selector([
    RainoneSelectors.has4gMobileOrder,
    RainoneSelectors.has4gMobileService,
    RainoneSelectors.has4gMobileMigrationOrder
  ])
  static hasMax4GMobileOrdersOrServices(
    has4gMobileOrder: number,
    has4gMobileService: number,
    has4gMobileMigrationOrder: number
  ) {
    return (has4gMobileOrder + has4gMobileService + has4gMobileMigrationOrder) >= 5
  }

}
