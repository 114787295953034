import { Utils } from "src/app/Utils";
import { CartVoucherOption } from "../../types/cart-voucher-option.interface";
import { GeForceTier } from "src/app/core/store/interfaces/product-catalog.interfaces";
import { SelectedProduct } from "../../interfaces/selected-product.interface";

// TODO clean this up

export class SetSelectedBundleId {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Set Bundle Id");
    constructor(public bundleId: string) { }
}

export class SetSelectedProduct {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set selected product");
  constructor(public payload: SelectedProduct) { }
}

export class SetSelectedNvidiaTier {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set Selected Nvidia Tier.");
  constructor(public nvidiaTier: GeForceTier) { }
}

export class SetRainOneSelected {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Set rainOne selected");
    constructor(public selected: boolean) { }
}
export class SetWorkProductSelected {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Set work product selected");
    constructor(public isSME: boolean) { }
}

export class SetMobileProductSelected {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set mobile product selected");
  constructor(public isMobileSelected: boolean) { }
}

// TODO this can be done better based off quantity
export class SetOnePackSelected {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set one pack selected");
  constructor(public isOnePackSelected: boolean) { }
}

export class SetThreePackSelected {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set three pack selected");
  constructor(public isThreePackSelected: boolean) { }
}

export class SetFivePackSelected {
  static readonly type = Utils.Helpers.Type("[Sales Cart] Set five pack selected");
  constructor(public isFivePackSelected: boolean) { }
}

export class SetNvidiaSelected {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Set Nvidia selected");
    constructor(public selected: boolean) { }
}

export class ClearSelectedBundleId {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Clear Bundle Id");
}


export class AddVoucher {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Add voucher");
    constructor(public voucherOption: CartVoucherOption) { }
}

export class RemoveVoucher {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Remove voucher");
    constructor(public voucherId: string) { }
}

export class ApplyVoucher {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Apply voucher");
    constructor(public voucherId: string) { }
}

export class WithdrawVoucher {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Withdraw voucher");
    constructor(public voucherId: string) { }
}


export class Clear {
    static readonly type = Utils.Helpers.Type("[Sales Cart] Clear");
}
