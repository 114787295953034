import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BasicStateUpdater } from 'src/app/shared/state-updaters/basic.state-updater';
import { BasicStateModel } from 'src/app/shared/state-updaters/types/basic-state-updater.types';
import { Subject } from 'rxjs';
import { PossibleDigitalIdentityCustomer } from '../../types/digital-identity-by-customer-id-response.interface';
import { DigitalIdentityService } from '../../services/digital-identity.service';
import { DigitalIdentityByUserIdActions } from '../actions/digital-identity-by-user-id';
import { AddInfoNotification } from 'src/app/core/store/actions/notifications.actions';
import { RicaAuthorityActions } from 'src/app/sales/sales-portal/rica-authority-page/rica-authority-store/actions';
import { CustomerInfoSummaryDestroyed } from 'src/app/customer-info-summary-page/store/actions/customer.actions';

export type DigitalIdentityByUserIdStateModel = BasicStateModel<PossibleDigitalIdentityCustomer>;

@State<DigitalIdentityByUserIdStateModel>({
  name: 'DigitalIdentityByUserIdState',
  defaults: BasicStateUpdater.getDefaultState()
})
@Injectable()
export class DigitalIdentityByUserIdState {

  @Selector()
  static isLoading(state: DigitalIdentityByUserIdStateModel) { return state.loading }

  @Selector()
  static isLoaded(state: DigitalIdentityByUserIdStateModel) { return state.loaded }

  @Selector()
  static getData(state: DigitalIdentityByUserIdStateModel) { return state.data }

  @Selector()
  static getError(state: DigitalIdentityByUserIdStateModel) { return state.error }

  private readonly _cancelRequest$ = new Subject();

  private readonly _stateUpdater = new BasicStateUpdater<PossibleDigitalIdentityCustomer, string>({
    cancelRequest$: this._cancelRequest$,
    errorMessage: "Failed to fetch digital identity.",
    notFoundMessage: "No digital identity.",
    baseFetchFn: (request: string) => this.digitalIdentityService.fetchDigitalIdentityByUserId(request),
    ...DigitalIdentityByUserIdActions,
  });

  constructor(private digitalIdentityService: DigitalIdentityService) { }

  @Action(DigitalIdentityByUserIdActions.Fetch)
  Fetch(ctx: StateContext<DigitalIdentityByUserIdStateModel>, action: DigitalIdentityByUserIdActions.Fetch) {
    ctx.dispatch(new AddInfoNotification("Verifying user."));
    return this._stateUpdater.Fetch(ctx, action);
  }

  @Action(DigitalIdentityByUserIdActions.FetchSuccess)
  FetchSuccess(ctx: StateContext<DigitalIdentityByUserIdStateModel>, action: DigitalIdentityByUserIdActions.FetchSuccess) {
    const { relatedParty } = action?.payload ?? {}
    ctx.dispatch(new RicaAuthorityActions.SetRelatedPartyIDs(relatedParty))
    this._stateUpdater.FetchSuccess(ctx, action);
  }

  @Action(DigitalIdentityByUserIdActions.FetchFail)
  FetchFail(ctx: StateContext<DigitalIdentityByUserIdStateModel>, action: DigitalIdentityByUserIdActions.FetchFail) {
    this._stateUpdater.FetchFail(ctx, action);
  }

  @Action([CustomerInfoSummaryDestroyed,DigitalIdentityByUserIdActions.Clear])
  Clear(ctx: StateContext<DigitalIdentityByUserIdStateModel>) {
    this._stateUpdater.Clear(ctx);
  }

}
