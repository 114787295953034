import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext, Store, createSelector } from "@ngxs/store";
import { CreateNewRainOneOrder, CreateNewRainOneOrderFail, CreateNewRainOneOrderSuccessful, RecreateMostRecentOrder } from "../../action/orders.actions";
import { SalesService } from "../../../sales-portal/services/sales.service";
import { tap } from "rxjs/operators";
import { GeneratePaymentLink } from "../../action/payments.actions";
import { AddSuccessResponseNotification } from "src/app/core/store/actions/notifications.actions";
import { GeneratePaymentLinkActionPayload } from "../../types/payment-link.interface";
import { CoreState } from "src/app/core/store/state/core.state";
import { Utils } from "src/app/Utils";
import { SalesPortalDestroyed } from "../../action/sales-portal.actions";
import { PopupError } from "src/app/core/handlers/popup-error";
import { SFValidators } from "src/app/shared/functions/sf-validators";


const getDefaults = (mostRecentOrderDetails: MostRecentOrderDetails = null): SalesOrdersStateModel => {
    return {
        loading: false,
        mostRecentOrderDetails
    }
}

interface MostRecentOrderDetails {
    action: CreateNewRainOneOrder;
    orderNumber: string;
}

export interface SalesOrdersStateModel {
    loading: boolean;
    mostRecentOrderDetails: MostRecentOrderDetails;
}

@State<SalesOrdersStateModel>({
    name: 'SalesOrdersState',
    defaults: getDefaults()
})

@Injectable()
export class SalesOrdersState {

    @Selector()
    static isLoading(state: SalesOrdersStateModel) { return state.loading; }

    @Selector()
    static getMostRecentOrderDetails(state: SalesOrdersStateModel) { return state.mostRecentOrderDetails; }

    static doesOrderMatchMostRecent(orderNumber: string | number) {
        return createSelector(
            [SalesOrdersState.getMostRecentOrderDetails],
            (details: MostRecentOrderDetails) => SFValidators.isDefined(orderNumber) && details?.orderNumber?.toString() === orderNumber?.toString()
        );
    }

    constructor(private salesService: SalesService,
        private store: Store) {
    }

    @Action(CreateNewRainOneOrder)
    createNewRainOneOrder(ctx: StateContext<SalesOrdersStateModel>, action: CreateNewRainOneOrder) {
        const { comms, payload, customer } = action;

        ctx.patchState({
            loading: true,
        })

        return this.salesService.createOrder(payload)
            .pipe(tap({
                next: res => ctx.dispatch(new CreateNewRainOneOrderSuccessful(comms, res?.result, customer, action)),
                error: (e: unknown) => ctx.dispatch(new CreateNewRainOneOrderFail(e))
            }))
    }

    @Action(CreateNewRainOneOrderSuccessful)
    createNewRainOneOrderSuccessful(ctx: StateContext<SalesOrdersStateModel>, action: CreateNewRainOneOrderSuccessful) {
        const { comms, response, customer, createOrderAction } = action;
        const { order_id, number, total_price, user_id } = response ?? {};
        const { email, phone, first_name, last_name } = customer ?? {};
        const smeInEmail = Utils.Regex.hasSMEInEmail(email)

        const modifiedEmail = smeInEmail ? email?.replace('+sme', "") : email

        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);

        ctx.patchState({
            loading: false,
            mostRecentOrderDetails: {
                action: createOrderAction,
                orderNumber: number
            }
        })

        const payload: GeneratePaymentLinkActionPayload = {
            orderNumber: +number,
            linkDetails: {
                name: first_name,
                surname: last_name,
                agentEmail,
                invoiceId: order_id,
                paymentChannel: 'order',
                customerId: user_id,
                amount: total_price,
                commsChannelType: comms,
                commsSender: comms === "email" ? modifiedEmail : phone
            }
        }

        return ctx.dispatch([
            new AddSuccessResponseNotification({
                success: true,
                message: "New order created"
            }),
            new GeneratePaymentLink(payload)
        ])
    }

    @Action(CreateNewRainOneOrderFail)
    createNewRainOneOrderFail(ctx: StateContext<SalesOrdersStateModel>, action: CreateNewRainOneOrderFail) {
        const error = Utils.Helpers.findError(action.error, "");
        const message = `Failed to create order. ${error}`;

        ctx.patchState({
            loading: false
        })

        ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message
        }));
    }

    @Action(RecreateMostRecentOrder)
    recreateMostRecentOrder(ctx: StateContext<SalesOrdersStateModel>, action: RecreateMostRecentOrder) {
        const { mostRecentOrderDetails } = ctx.getState();

        if (!mostRecentOrderDetails?.action) {
            throw new PopupError("Could not find recently created order details.");
        }

        if (mostRecentOrderDetails?.orderNumber?.toString() !== action.orderNumber?.toString()) {
            throw new PopupError("Order number does not match recently created order.");
        }

        return ctx.dispatch(mostRecentOrderDetails.action);
    }


    @Action(SalesPortalDestroyed)
    Clear(ctx: StateContext<SalesOrdersStateModel>) {
        const { mostRecentOrderDetails } = ctx.getState();
        ctx.setState(getDefaults(mostRecentOrderDetails));
    }
}
