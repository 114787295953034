
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AddSuccessResponseNotification } from 'src/app/core/store/actions/notifications.actions';
import { Utils } from 'src/app/Utils';
import { WhatsappService } from '../../services/whatsapp.service';
import { WhatsappMessageActions } from '../actions/whatsapp-message-actions';


interface WhatsappSendMessageStateModel {
    loading: boolean;
}
@State<WhatsappSendMessageStateModel>({
    name: 'sf_whatsapp_send_message_state',
    defaults: {
        loading: false
    }
})
@Injectable()
export class WhatsappSendMessageState {

    @Selector()
    static isLoading(state: WhatsappSendMessageStateModel) { return state.loading }

    constructor(private whatsappService: WhatsappService) {
    }

    @Action(WhatsappMessageActions.Send)
    Send(ctx: StateContext<WhatsappSendMessageStateModel>, action: WhatsappMessageActions.Send) {
        const { payload } = action;

        ctx.patchState({ loading: true });

        return this.whatsappService.sendMessage(payload)
            .pipe(tap({
                next: res => ctx.dispatch(new WhatsappMessageActions.SendSuccess(res)),
                error: (e: unknown) => ctx.dispatch(new WhatsappMessageActions.SendFail(e))
            }));
    }


    @Action(WhatsappMessageActions.SendSuccess)
    SendSuccess(ctx: StateContext<WhatsappSendMessageStateModel>) {
        ctx.patchState({ loading: false });
    }


    @Action(WhatsappMessageActions.SendFail)
    SendFail(ctx: StateContext<WhatsappSendMessageStateModel>, action: WhatsappMessageActions.SendFail) {
        ctx.patchState({ loading: false });

        const error = Utils.Helpers.findError(action.error, '');
        return ctx.dispatch(new AddSuccessResponseNotification({
            success: false,
            message: error
        }));

    }

}   