import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiOptions } from 'src/app/shared/services/api-service/api-response.interface';
import { environment } from 'src/environments/environment';
import { CustomerPayload } from '../../store/interfaces/create-customer.interface';
import { UpdateUser } from '../../store/interfaces/update-user.interface';
import { NewOrder, OrderResponse } from '../../store/interfaces/create-order.interface';
import { Store } from '@ngxs/store';
import { ProductCatalogState } from 'src/app/core/store/state/product-state/product-catalog.state';
import { BillingCyclePayload, BillingCycleResponse } from '../../store/interfaces/bill-lifecycle-payload.interface';
import { OrderPaymentLinkRef, PaymentLinkPayload, TopupPaymentLinkPayload } from '../../store/types/payment-link.interface';
import { PartnerDetails } from '../../store/interfaces/partner.interface';
import { CoreState } from 'src/app/core/store/state/core.state';
import { InteractionTicketsState } from 'src/app/interactions/store/state/interaction-tickets.state';
import { Utils } from 'src/app/Utils';
import { Navigate } from '@ngxs/router-plugin';
import { CUSTOMER_INFO_SUMMARY_ROUTE, DEFAULT_HTTP_OPTIONS } from 'src/app/constants';
import { CustomerInfoState } from 'src/app/customer-info-summary-page/store/state/customer-info.state';
import { DigitalIdentityCustomer } from 'src/app/customer-data-components/axiom/digital-identity/types/digital-identity-by-customer-id-response.interface';

@Injectable({
    providedIn: "root"
})
export class SalesService {

    constructor(
        private http: HttpClient,
        private store: Store
    ) { }

    createCustomer(payload: CustomerPayload) {

      const { user, partner, optIn } = payload ?? {}

        const url = `${environment.main_proxy}/axiom/digital-identity/digitalIdentity/create`;

        return this.http.post<DigitalIdentityCustomer>(url, {user, partner, optIn}, DEFAULT_HTTP_OPTIONS);
    }

    public resetCustomerPassword(customerEmail: string) {
        const url = `${environment.main_proxy}/axiom/digital-identity/authenticate/password_reset/${customerEmail}`
        return this.http.get(url);
    }

    updateCustomer(updateUser: UpdateUser, id: string) {
        const url = `${environment.main_proxy}/fullcircle/api/v8/partner/users/${id}`;
        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };
        return this.http.patch(url, updateUser, httpOptions);
    }

    public getUser(searchVariable: string, searchValue: string) {
        const url = `${environment.main_proxy}/fullcircle/api/v5/partner/detailed_customer_profile/${searchVariable}/${searchValue}`;
        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        }
        return this.http.get(url, httpOptions);
    }

    public createOrder(payload: NewOrder) {
        const url = `${environment.main_proxy}/order-proxy/order`

        const httpOptions: ApiOptions = {

            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })

        }
        return this.http.post<{ result: OrderResponse }>(url, payload, httpOptions);
    }

    public getService(service_id: string) {
        const url = `${environment.main_proxy}/new-service-proxy/service/${service_id}`

        const httpOptions: ApiOptions = {

            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })

        }
        return this.http.get(url, httpOptions)
    }

    public generatePaymentLink(payload: PaymentLinkPayload | TopupPaymentLinkPayload) {
        const url = `${environment.main_proxy}/payment-gateway/payments/generate/link`

        const httpOptions: ApiOptions = {

            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })

        }
        return this.http.post<void>(url, payload, httpOptions)
    }


    public cancelPaymentLinksForExistingOrder(orderNumber: number) {
        const merchantTransactionId: OrderPaymentLinkRef = `order - ${orderNumber}`
        const url = `${environment.main_proxy}/payment-gateway/payments/cancel/link/merchant-transaction-id/${merchantTransactionId}`;

        const httpOptions: ApiOptions = {

            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })

        }
        return this.http.post<void>(url, {}, httpOptions)
    }


    public getAllRainOne4GProducts() {
        const productCatalogue = this.store.selectSnapshot(ProductCatalogState.getData);

        return productCatalogue.sim.filter(p => (p.name.includes('rainONE') && p.category === '4G')).map((p) => {
            return { 'line_name': p.name, 'line_id': p.id, 'line_price': p.price }
        })
    }

    public getAllRainOneWifiAddonProducts() {
        const productCatalogue = this.store.selectSnapshot(ProductCatalogState.getData);

        return productCatalogue.addon.filter((a) => (
            a.name.includes('Mbps'))
        ).map((m) => {
            return { 'wifi_speed_level_name': m.name, 'wifi_speed_level_id': m.id, 'wifi_speed_level_price': m.price }
        });

    }

    public setBillingCycle(billingPayload: BillingCyclePayload) {
        const url = `${environment.main_proxy}/fullcircle/api/v5/partner/billing/set_billcycle`;
        const httpOptions: ApiOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),

        };
        return this.http.post<BillingCycleResponse>(url, billingPayload, httpOptions);

    }

    getPartnerDetails(): PartnerDetails {
        const agentEmail = this.store.selectSnapshot(CoreState.getAgentEmail);
        const selectedHexId = this.store.selectSnapshot(InteractionTicketsState.getCurrentHexId);

        return {
            id: selectedHexId ?? Utils.Helpers.createTimestampReference(agentEmail),
            comments: 'snowflake-system',
            agent_email: agentEmail
        }
    }

    gotoCustomerPage() {
        const email = this.store.selectSnapshot(CustomerInfoState.getCustomerEmail);
        this.store.dispatch(new Navigate([CUSTOMER_INFO_SUMMARY_ROUTE, email]));
    }

}
