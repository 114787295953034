import { Utils } from "src/app/Utils";


export class SetSpeedLevel {
    static readonly type = Utils.Helpers.Type("[Sales Speedup Levels] Set Level");
    constructor(public levelId: string) { }
}

export class SetSpeedAtBaseLevel {
    static readonly type = Utils.Helpers.Type("[Sales Speedup Levels] Set Base Level");
}

export class Clear {
    static readonly type = Utils.Helpers.Type("[Sales Speedup Levels] Clear");
}