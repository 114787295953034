import { faSearch, faUser, faUserCog, faTicketAlt, faMoneyBillWave } from '@fortawesome/free-solid-svg-icons';
import { ALL, SF_AUTH_ROLES } from '../auth/assets/auth-role.config';
import { Utils } from '../Utils';
import { RouteInfo } from './route-info.interface';



type RouteCardInfo = Omit<RouteInfo, "disabled"> & {
  allowedRoles: string[],
  unallowedRoles?: string[]
}

const getHomeCards = (): RouteCardInfo[] => [
  {
    header: "search",
    route: "/search",
    icon: faSearch,
    allowedRoles: [ALL]
  },
  {
    header: "tickets",
    route: "/tickets",
    icon: faTicketAlt,
    allowedRoles: [ALL],
    unallowedRoles: [
      "CEC.UPGRADES.A",
      "CEC.SALES.A",
      "CEC.SALES.B",
      "CEC.SALES.C",
      "CEC.SALES.CCI",
      "CEC.NO_CONTACT.CCI",
      "CEC.SALESPRIORITY.A",
      "CEC.SALES_REALTIME.A",
      "CCD.THIRD_PARTY.A"
    ]
  },
  {
    header: "agent view",
    route: "/agent-view",
    icon: faUser,
    allowedRoles: [ALL],
    unallowedRoles: [
      "CCD.THIRD_PARTY.A"
    ]
  },
  {
    header: "admin",
    route: "/admin",
    icon: faUserCog,
    allowedRoles: [SF_AUTH_ROLES.MANAGEMENT, SF_AUTH_ROLES.WIPROGEN]
  },
  {
    header: "assisted sales",
    route: "/sales-portal",
    icon: faMoneyBillWave,
    allowedRoles: [
      "CEC.UPGRADES.A",
      "CEC.SALES.A",
      "CEC.SALES.B",
      "CEC.SALES.C",
      "CEC.SALES.CCI",
      "CEC.NO_CONTACT.CCI",
      "RETAIL.CUSTOMER_ENGAGEMENT.A",
      "CEC.SALESPRIORITY.A",
      "CEC.SALES_REALTIME.A"
    ]
  },
];

const CARD_MAP = {
  "home": getHomeCards
} as const;

export type RouterCardOption = keyof typeof CARD_MAP;


function isEnabledCard(agentRoles: string[], allowedRoles: string[], unallowedRoles: string[]) {
  if (allowedRoles.includes(ALL) && unallowedRoles?.length <= 0) {
    return true;
  }


  return Utils.SfHelpers.hasAccess(agentRoles, allowedRoles, unallowedRoles);
}


export function getRouterCardsInfo(agentRoles: string[], section: RouterCardOption): RouteInfo[] {
  const cards = CARD_MAP[section]();


  return cards.map(card => {
    const isEnabled = isEnabledCard(agentRoles, card.allowedRoles, card.unallowedRoles);

    return {
      ...card,
      disabled: !isEnabled
    }

  });
}
