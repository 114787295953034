import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { SFValidators } from 'src/app/shared/functions/sf-validators';
import { WhatsappMediaActions } from '../../store/actions/whatsapp-media-actions';
import { getWhatsappMessageContent } from '../../store/functions/get-whatsapp-message-content';
import { WhatsappMediaStateModel, WhatsappMediaState } from '../../store/state/whatsapp-media.state';
import { FormattedWhatsappMessage, NonTextCurrentMediaType } from '../../store/types/whatsapp-message.interface';

@Component({
  selector: 'sf-whatsapp-media-message',
  templateUrl: './whatsapp-media-message.component.html',
  styleUrls: ['./whatsapp-media-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WhatsappMediaMessageComponent implements OnInit {

  @Input() message: FormattedWhatsappMessage<NonTextCurrentMediaType>;

  mediaState$: Observable<WhatsappMediaStateModel["messageIds"][string]>;

  constructor(private store: Store) { }


  ngOnInit(): void {
    this.mediaState$ = this.store.select(WhatsappMediaState.getState(this.message?.id));
  }

  toggleMedia() {
    const { id, type } = this.message ?? {};
    const { presignedUrl, mimetype } = this.getMessageContent();

    SFValidators.warnAboutMissingFields({ id, type, mimetype, presignedUrl }, "Failed to open media.");

    this.store.dispatch(new WhatsappMediaActions.FetchOrToggle({
      id,
      type,
      mimeType: <string>mimetype,
      dataUrl: presignedUrl
    }));
  }

  getMessageContent() {
    const { type, content } = this.message ?? {};
    return getWhatsappMessageContent(type, content);
  }

}
