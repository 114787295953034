import { Utils } from "src/app/Utils";
import { CustomerPayload } from "../interfaces/create-customer.interface";
import { DigitalIdentityCustomer } from "src/app/customer-data-components/axiom/digital-identity/types/digital-identity-by-customer-id-response.interface";


export class CreateNewCustomer {
    static readonly type = Utils.Helpers.Type('[Sales] Create New Customer');
    constructor(public payload: CustomerPayload) {}
}

export class AddUserProfileCompanyDetails {
    static readonly type = Utils.Helpers.Type('[Sales] Add user profile company details');
    constructor(public payload: CustomerPayload) {}
}
export class AddUserProfileCompanyDetailsSuccess {
    static readonly type = Utils.Helpers.Type('[Sales] Add user profile company details success');
    constructor(public payload: DigitalIdentityCustomer) {}
}

export class CreateNewCustomerSuccess {
    static readonly type = Utils.Helpers.Type('[Sales] Create Customer Successful');
    constructor(public payload: { response: DigitalIdentityCustomer, optIn?: boolean}) { }
}

export class CreateNewCustomerFail {
    static readonly type = Utils.Helpers.Type('[Sales] Create Customer Fail');
    constructor(public error: unknown) { }
}

export class ResetCustomerPassword {
    static readonly type = Utils.Helpers.Type('[Sales] Reset Customer Password');
    constructor(public customerEmail: string) { }
}

export class ResetCustomerPasswordSuccess {
    static readonly type = Utils.Helpers.Type('[Sales] Reset Customer Password Successful');
}

export class ResetCustomerPasswordFail {
    static readonly type = Utils.Helpers.Type('[Sales] Reset Customer Password Fail');
    constructor(public error: unknown) { }
}
