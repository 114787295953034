import { SpeedupPolicyOption } from "src/app/customer-info-summary-page/store/interfaces/basic-customer-service.interface";
import { HomeOrWork } from "src/app/customer-info-summary-page/store/state/home-or-work-services.state";



//TODO: cleanup config
export const RAIN_WIFI_SPEED_LEVELS: WifiSpeedupConfig[] = [
  {
    id: '1',
    level: 30,
    name: '30 Mbps',
    speed: '30',
    price: 0,
    cost: 'FREE',
    levelCost: 'included in rainone',
    policy: 'High_Speed_30',
    type: 'home'
  },
  {
    id: '2',
    level: 60,
    name: 'Level up 60 Mbps',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },
  {
    id: '3',
    level: 100,
    name: 'Level up 100+ Mbps',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },
  {
    id: '4',
    level: 30,
    name: 'Level 30 Mbps AU',
    speed: '30',
    price: 0,
    cost: 'FREE',
    levelCost: 'included in rainone',
    policy: 'High_Speed_30',
    type: 'work'
  },
  {
    id: '5',
    level: 60,
    name: 'Level up SME 60 Mbps AU',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },
  {
    id: '6',
    level: 100,
    name: 'Level up SME 100plus Mbps AU',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },

  // new products speed config

  // HOME PRO

  // home 60 speed ups
  {
    id: '7',
    level: 60,
    name: 'Level up 60 Mbps 101 pro',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  {
    id: '8',
    level: 60,
    name: 'up to 60 Mbps - 1 xtender',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  {
    id: '9',
    level: 60,
    name: 'up to 60 Mbps - 2 xtenders',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'home'
  },

  // home 100 speed ups

  {
    id: '10',
    level: 100,
    name: 'Level up 100+ Mbps 101 pro',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  {
    id: '11',
    level: 100,
    name: 'unlimited 5G speeds - 1 xtender',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  {
    id: '12',
    level: 100,
    name: 'unlimited 5G speeds - 2 xtenders',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'home'
  },

  // WORK PRO

  // work 60 speed ups
  {
    id: '13',
    level: 60,
    name: 'Level up 60 Mbps 101.pro AU',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  {
    id: '14',
    level: 60,
    name: 'up to 60 Mbps - 1 xtender - work',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  {
    id: '15',
    level: 60,
    name: 'up to 60 Mbps - 2 xtenders - work',
    speed: '60',
    price: 200,
    cost: 'R200',
    levelCost: 'at R200 per level per month',
    policy: 'High_Speed_60',
    type: 'work'
  },

  // work 100 speed ups

  {
    id: '16',
    level: 100,
    name: 'Level up 100+ Mbps 101.pro AU',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },
  {
    id: '17',
    level: 100,
    name: 'unlimited 5G speeds - 1 xtender - work',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },
  {
    id: '18',
    level: 100,
    name: 'unlimited 5G speeds - 2 xtenders - work',
    speed: '100+',
    price: 400,
    cost: 'R400',
    levelCost: 'at R400 per level per month',
    policy: 'High_Speed_Unlimited',
    type: 'work'
  },

];


export const RAIN_WIFI_BASE_LEVEL = RAIN_WIFI_SPEED_LEVELS[0];
export const RAIN_WORK_WIFI_BASE_LEVEL = RAIN_WIFI_SPEED_LEVELS[3];

export type WifiSpeedupConfig = {
  id: string;
  level: number;
  name: string;
  speed: "30" | "60" | "100+";
  price: number;
  cost: string;
  levelCost: string;
  policy: SpeedupPolicyOption;
  type: HomeOrWork
}
