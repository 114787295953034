import { SFValidators } from "src/app/shared/functions/sf-validators";


export function hasSMEInEmail(email: string): boolean {

  if (SFValidators.isNotDefined(email)) {
    return
  }

  const regex = new RegExp(/^[^\s@]+(\+sme)[^\s@]*@[^\s@]+\.[^\s@]+$/)

  return regex.test(email)

}


export function addSMEToEmail(email: string): string {

  if (SFValidators.isNotDefined(email)) {
    return
  }

  return email.replace(/^([^@]+)(@.+)$/, '$1+sme$2');
}

